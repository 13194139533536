<template>
    <div>
        <div class="title-box">
            <i-sticky>
                <i-header :scrollContainer="'eventDetailContainer'" title="会议详情" theme="light" :showBack="true"
                          @back-event="backPage">
                    <div v-if="showEdit" class="right-actions" slot="right" @click="navToEdit">编辑</div>
                </i-header>
            </i-sticky>
            <component v-if="mianLabelsTemplate" :is="$evalTemplate('Vnode', this.mianLabelsTemplate)"
                       @handleMoreTips="showMoreTips = !showMoreTips"></component>
            <div v-else class="confirmInfo">
                <p class="confirmInfoHead">{{ miceInfo.name }}</p>
                <p class="confirmInfoCode">{{ miceInfo.groupNum }}</p>
                <div class="confirmInfoMsg">
                    <div v-if="miceInfo.eventDate">
                        <i-icon :size="15" color="#fff" name="icon-rili"/>
                        {{ miceInfo.eventDate.split("T")[0] }} {{ miceInfo.eventTime.startTime }} ~
                        {{ miceInfo.eventTime.endTime }}
                    </div>
                    <div>
                        <i-icon :size="15" color="#fff" name="icon-weizhi"/>
                        {{ miceInfo.city && miceInfo.city.cityDictTxt }}
                    </div>
                    <div>
                        <i-icon :size="15" color="#fff" name="icon-banhuiren"/>
                        {{ miceInfo.qtySpeaker > 0 ? '有讲者' : '无讲者' }}
                    </div>
                </div>
                <div class="showMoreTips" @click="showMoreTips = !showMoreTips">
                    {{ showMoreTips ? '收起查看详细信息' : '展开查看详细信息' }}
                    <i-icon :size="10" color="#fff" :name="showMoreTips ? 'icon-jiantoushang' : 'icon-jiantouxia'"/>
                </div>
            </div>
            <i-skeleton v-if="!miceInfo.Name" :rowStyle="rowStyle"/>
        </div>
        <div class="baseEventInfo" v-if="miceInfo.name && showMoreTips" :style="{ height: titleBoxOffsetTop }">
            <div class="title">会议基本信息</div>
            <div class="cnt-box">
                <template v-for="(item, index) in DirectoryIdMatchInfo">
                    <div class="cnt-item" :style="item.style || ''" :key="index"
                         v-if="!$evalTemplate(item.visibleRuleExpr)">
                        <div class="left">{{ item.label }}</div>
                        <iNoCnt class="right" :txt="$evalTemplate(item.render)"></iNoCnt>
                    </div>
                </template>
            </div>
            <div class="choseBox" @click="showMoreTips = false">
                <i-icon :size="20" color="#909399" name="icon-jiantoushang"/>
            </div>
        </div>
        <div class="container">
            <div class="dead_line">
                <div class="text">
                    <i-icon :size="16" color="#333" name="icon-rili" class="date-icon"/>
                    截止参团日期
                </div>
                <div class="date">{{ miceInfo.expiryDate && miceInfo.expiryDate.split('T')[0] }}</div>
            </div>
            <div class="card">
                <div class="card_title">团长信息</div>
                <div class="card_item margin-btm-20">
                    <div class="name">
                        <div class="inner_name">{{ miceInfo.dspName }}</div>
                        <div class="tag">{{ miceInfo.applyUserOrganization && miceInfo.applyUserOrganization.bussinesUnitCode }}</div>
                    </div>
                    <div class="staff_num">员工号：{{ miceInfo.createdBy }}</div>
                    <div class="email">{{ miceInfo.email }}</div>
                    <div class="email">{{ miceInfo.phoneNumber }}</div>
                </div>
            </div>
            <div class="card padding-btm-30">
                <div class="card_title">当前跟团记录
                    <div class="num">讲者人数({{ miceInfo.totalSpeaker }}) | 参会人数({{ miceInfo.totalQtyAttend }})
                    </div>
                </div>
                <div class="card_item" v-for="item of groupInfo" :key="item.joinId">
                    <div class="name">
                        <div class="inner_name">{{ miceInfo.dspName }}</div>
                        <div class="tag">{{ miceInfo.applyUserOrganization && miceInfo.applyUserOrganization.bussinesUnitCode }}</div>
                        <div class="tag">{{item.qtySpeaker > 0 ? '有讲者' : '无讲者'}}</div>
                    </div>
                    <div class="staff_num">员工号：{{ item.createdBy }}</div>
                    <div class="item_bottom">
                        <div class="email_div">
                            <div class="name">{{ item.email ? item.email : '暂无邮箱'}}</div>
                            <div class="name">{{ item.phoneNumber }}</div>
                        </div>
                        <div class="operations" v-if="isEditRecord(item)" @click="navToEditRecord(item)">编辑</div>
                        <div class="operations" v-else @click="navToEditRecord(item, 'disabled')">查看</div>
                    </div>

                </div>
            </div>
        </div>
        <template v-if="![-1, 10, 0].includes(miceInfo.status) && !isExpired()">
            <div class="bottom_btns btns_1" v-if="!isApplier">
                <van-button type="primary" v-if="isJoined" @click="quit">退团</van-button>
                <van-button type="primary" @click="joinGroup" v-else>参团</van-button>
            </div>
            <div v-else class="bottom_btns">
                <van-button type="default" @click="quit">退 团</van-button>
                <van-button @click="cancel">取消开团</van-button>
                <van-button type="primary" @click="openingGroup">开团成功</van-button>
            </div>
        </template>
    </div>
</template>

<script>
import {iNoCnt} from "@/components/iToolCase";
import {getCookie} from "tiny-cookie";
import {Button, Dialog} from 'vant';

export default {
    name: "groupMeetingInfoCard",
    data() {
        return {
            getCookie,
            showMoreTips: false
        }
    },
    components: {iNoCnt, Button},
    props: {
        infoTemplate: {
            type: Array
        },
        mianLabelsTemplate: {
            type: [String, Object]
        },
        groupInfo: {
            type: [Array, Object]
        },
        miceInfo: null,
        otherBtnList: [],
    },
    methods: {
        // 去编辑拼团
        navToEditRecord(item, flag){
            window.currentjoinitem = item;
            const params = {
                isEdit: true
            }
            if(flag) params.readOnly = true;
            this.$router.push({path: '/EventCenterEditorGroup', query: {
                ...params
            }})
        },
        // 判断是编辑还是查看
        isEditRecord({memberId}){
            return !!(memberId === getCookie('userId') && !this.isExpired())
        },
        // 参团
        joinGroup(){
            this.$store.commit("setValue", {
                key: "miceInfo",
                value: this.miceInfo,
            });
            this.$router.push('/EventCenterEditorGroup');
        },
        // 处理的参数、接口返回值的公共方法
        publicApi({status, clientAction}){
            const params = {
                "schema":"smartx_meta",
                "object":"group_event_infos",
                "clientAction": clientAction,
                "action":"U",
                "metas":[
                    {"infoId": this.miceInfo.infoId, status}
                ]
            }
            return new Promise(resolve => {
                this.$service.GenericCommit(params).then(res => {
                    resolve(res)
                })
            })
        },
        // 退团
        quit(){
            Dialog.confirm({
                title: '提示',
                message: '确认退团吗？'
            }).then(async () => {
                const params = {
                    "clientAction":"quit"
                }
                const result = await this.publicApi(params);
                if(result.success){
                    this.$emit('getConfigAndDetail');
                }
            })
        },
        // 取消开团
        cancel(){
            Dialog.confirm({
                title: '提示',
                message: '确认取消开团吗？'
            }).then(async () => {
                const params = {
                    "clientAction":"cancel",
                    "status": -1
                }
                const result = await this.publicApi(params);
                if(result.success){
                    this.$itoast.success('取消成功');
                    this.$emit('getConfigAndDetail');
                }
            })
        },
        // 开团
        async openingGroup(){
            const params = {
                "clientAction": "start_group",
                "status":10
            }
            const result = await this.publicApi(params);
            if(result.success){
                this.$itoast.success('开团成功');
                this.$emit('getConfigAndDetail');
            }
        },
        // 编辑
        navToEdit(){
            // this.$smartStorage.set('pfizerGroupInfo', JSON.stringify(this.miceInfo));
            const {
                name,
                eventDate,
                expiryDate,
                city,
                eventTime,
                hospitalData,
                qtySpeaker,
                speekerData,
                qtyAttend,
                productData,
                therapeuticScopesData,
                products,
                extData,
                groupNum,
                infoId} = this.miceInfo;
            console.log('miceinfo',this.miceInfo);
            const formData = {
                        name: name,
                        eventCity: `${city.provinceDictVal}/${city.cityDictVal}/${city.countyDictVal}`,
                        // DtStartDate: eventDate,
                        DtEndd: expiryDate,
                        DtStart: eventTime.startTime,
                        DtEnd: eventTime.endTime,
                        DtEndDate: eventTime.alternativeDate,
                        DtEnddd: eventTime.alternativeStartTime,
                        DtEnddsd: eventTime.alternativeEndTime,
                        hospital: hospitalData.hospitalCode,
                        hospitalType: hospitalData.hospitalTypeVal,
                        productrrFields: hospitalData.hospitalDepartmentVal,
                        officeAdd44ressd: qtySpeaker,
                        hospitalTyspe1: speekerData.contentTypeVal,
                        officeAd3dressd: speekerData.speakerInfo,
                        officeAd2dressd: qtyAttend,
                        proerduct: productData.productVal,
                        producst: therapeuticScopesData.therapeuticScopesVal,
                        products: qtySpeaker > 0 ? '有讲者' : '没有讲者',
                        groupNum: groupNum,
                        infoId: infoId,
                        ExtData: {
                            DtStartDate: eventDate,
                            DtStart: eventTime.startTime,
                            DtEnd: eventTime.endTime,
                            DtEndDate: eventTime.alternativeDate,
                            DtEnddd: eventTime.alternativeStartTime,
                            DtEnddsd: eventTime.alternativeEndTime,
                            DtEndd: expiryDate,
                        }
            }
            console.log(speekerData);
            console.log('formData--编辑',formData);
            this.$store.commit("setValue", {
                key: "miceInfo",
                value: formData,
            });
            this.$router.push({ name: "createEvent", query: {isEdit: true, isDraft: this.miceInfo.status === 0} });
        },
        backPage() {
            this.$router.go(-1)
        },
        // 是否截至
        isExpired(){
            if(!this.miceInfo.expiryDate) return
            const dateExpire = this.miceInfo.expiryDate.replace(/T00:00:00/g, 'T23:59:59');
            const difference = new Date().getTime() - new Date(dateExpire).getTime();
            return difference > 0; // 大于0是过期
        },
    },
    computed: {
        isApplier(){ // 区分是参团的人还是发起人（申请者）
            return this.miceInfo.applyUserId === getCookie('userId')
        },
        isJoined(){ // 是否已经参团，如果已经参团展示退团，没有参团展示参团
            return this.groupInfo.length && this.groupInfo.some(i => {return i.memberId === getCookie('userId')})
        },
        // eslint-disable-next-line vue/return-in-computed-property
        DirectoryIdMatchInfo() {
            if (this.miceInfo && this.infoTemplate.length) {
                let context = this;
                console.log(this.infoTemplate.find((x) => eval(x.expr))?.labels);
                try {
                    return this.infoTemplate.find((x) => eval(x.expr))?.labels;
                } catch (error) {
                    console.log(error);
                }
            } else {
                return [];
            }
        },
        showEdit(){ // 何时可编辑？没有过期，状态不为-1和10，且是申请者（创建者）本人
            return this.miceInfo.expiryDate && ![-1, 10].includes(this.miceInfo.status) && !this.isExpired() && this.isApplier
        }
    }
}
</script>

<style scoped lang="less">
.confirmMenu {
    li {
        height: 0.45rem;
        width: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 0.13rem;
        position: relative;

        svg {
            margin-right: 5px;
        }
    }

    li:last-child {
        border-bottom: 0;
    }
}

.title-box {
    background: var(--themeColor);
    border-bottom-left-radius: 0.34rem;
    border-bottom-right-radius: 0.34rem;
    .i-header{
        background-color: var(--themeColor);
    }
    .confirmInfo {
        box-sizing: border-box;
        padding: 0.08rem 0.25rem 0.14rem 0.25rem;
        color: #fff;
        //border-bottom-left-radius: 0.34rem;
        //border-bottom-right-radius: 0.34rem;

        .confirmInfoHead {
            font-size: 0.22rem;
            text-align: left;
        }

        .confirmInfoCode {
            font-size: 0.14rem;
            text-align: left;
        }

        .confirmInfoMsg {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 0.1rem;
            color: #fff;
            font-size: 0.14rem;

            div {
                margin-top: 0.05rem;
                display: flex;
                align-items: center;

                svg {
                    padding-right: 0.08rem;
                }
            }

            // :nth-child(1n) {
            //   width: 60%;
            // }

            // :nth-child(2n) {
            //   width: 40%;
            // }

            // :nth-child(3) {
            //   margin-top: 0.11rem;
            // }

            // :nth-child(4) {
            //   margin-top: 0.11rem;
            // }
        }

        .showMoreTips {
            margin-top: 0.14rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.12rem;
            font-weight: 500;
            color: #fff;

            svg {
                margin-left: 0.05rem;
            }
        }
    }
}

.baseEventInfo {
    width: 100vw;
    height: calc(100vh - 1.95rem);
    background-color: #FFFFFF;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0.2rem 0.25rem;
    padding-top: 0;
    box-sizing: border-box;
    z-index: 100;
    overflow-y: auto;

    .title {
        font-size: 0.16rem;
        font-weight: bold;
        color: #000000;
    }

    .cnt-box {
        margin-top: 0.2rem;
        min-height: 3rem;

        .cnt-item {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 0.1rem;

            .left {
                width: 35%;
                font-size: 0.15rem;
                font-weight: 500;
                color: #909398;
            }

            .right {
                width: 65%;
                text-align: right;
                font-size: 0.15rem;
                font-weight: 500;
                color: #13161B;
            }
        }
    }

    .choseBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.2rem;
    }
}

.confirmEventInfo {
    background: #f6f7fb;
    padding: 0.15rem 0.16rem 0.15rem 0.16rem;
    border-bottom-left-radius: 0.34rem;
    border-bottom-right-radius: 0.34rem;
    font-size: 0.12rem;

    .confirmEventMsg {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {
            width: 50%;
            color: #909399;
            text-align: left;
            margin-bottom: 0.1rem;
            display: flex;
            line-height: 0.15rem;
            align-items: center;
            font-size: 0.12rem;

            :nth-child(1) {
                min-width: 0.68rem;
                text-align: left;
            }

            :nth-child(2) {
                color: #13161a;
            }
        }

        & > span {
            margin-left: 0.1rem;
        }
    }
}

.container {
    padding: 0 0.25rem;

    .dead_line {
        height: 0.5rem;
        border-radius: 0.12rem;
        background-color: #F5F5F5;
        padding: 0 0.22rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #13161A;
        font-size: 0.16rem;
        font-weight: bold;
        margin: 0.24rem 0;

        .text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 0.16rem;

            .date-icon {
                font-size: 0.16rem;
                margin-right: 0.08rem;
                font-weight: bolder;
                stroke: #13161A;
                stroke-width: 0.02rem;
            }
        }

        .date {
            font-size: 0.16rem;
        }
    }

    .card {
        .card_title {
            font-weight: bold;
            font-size: 0.16rem;
            color: #13161A;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.14rem;

            .num {
                font-size: 0.12rem;
                color: #909399;
                font-weight: lighter;
            }
        }

        .card_item {
            padding: 0.13rem 0.14rem 0.15rem 0.14rem;
            border-radius: 0.1rem;
            box-shadow: 0px 0px 0.16rem 0px rgba(0, 0, 0, 0.08);
            margin-bottom: 0.1rem;

            .name {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .inner_name{
                    font-size: 0.16rem;
                    font-weight: bold;
                    color: #13161A;
                    line-height: 1;
                }
                .tag {
                    margin-left: 0.1rem;
                    padding: 0.04rem 0.06rem;
                    border-radius: 0.03rem;
                    color: var(--themeColor);
                    font-size: 0.10rem;
                    font-weight: lighter;
                    background-color: var(--gradualColor);
                }
            }
            .staff_num{
                line-height: 1;
                font-size: 0.12rem;
                margin-top: 0.04rem;
                margin-bottom: 0.12rem;
                color: #909398;
            }
            .item_bottom{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .email_div{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
                .operations{
                    width: 0.6rem;
                    height: 0.3rem;
                    color: var(--themeColor);
                    background-color: var(--gradualColor);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0.15rem;
                }
            }
            .email{
                font-size: 0.14rem;
                font-weight: normal;
                color: #13161A;
                line-height: 1;
                &:nth-last-child(1){
                    margin-top: 0.04rem;
                }
            }
        }

        .margin-btm-20 {
            margin-bottom: 0.2rem;
        }
    }
    .padding-btm-30{
        padding-bottom: 0.3rem;
    }
}
.bottom_btns{
    width: calc(100% - 0.5rem);
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.05rem 0.25rem;
    padding-bottom: 0.34rem;
    .van-button{
        width:30%;
        //border-radius: 0.22rem!important;
    }
}

.btns_1{
    .van-button{
        width:100%;
        //border-radius: 0.22rem!important;
    }
}

</style>
