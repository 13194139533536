var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "title-box" },
        [
          _c(
            "i-sticky",
            [
              _c(
                "i-header",
                {
                  attrs: {
                    scrollContainer: "eventDetailContainer",
                    title: "会议详情",
                    theme: "light",
                    showBack: true,
                  },
                  on: { "back-event": _vm.backPage },
                },
                [
                  _vm.showEdit
                    ? _c(
                        "div",
                        {
                          staticClass: "right-actions",
                          attrs: { slot: "right" },
                          on: { click: _vm.navToEdit },
                          slot: "right",
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm.mianLabelsTemplate
            ? _c(_vm.$evalTemplate("Vnode", this.mianLabelsTemplate), {
                tag: "component",
                on: {
                  handleMoreTips: function ($event) {
                    _vm.showMoreTips = !_vm.showMoreTips
                  },
                },
              })
            : _c("div", { staticClass: "confirmInfo" }, [
                _c("p", { staticClass: "confirmInfoHead" }, [
                  _vm._v(_vm._s(_vm.miceInfo.name)),
                ]),
                _c("p", { staticClass: "confirmInfoCode" }, [
                  _vm._v(_vm._s(_vm.miceInfo.groupNum)),
                ]),
                _c("div", { staticClass: "confirmInfoMsg" }, [
                  _vm.miceInfo.eventDate
                    ? _c(
                        "div",
                        [
                          _c("i-icon", {
                            attrs: {
                              size: 15,
                              color: "#fff",
                              name: "icon-rili",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.miceInfo.eventDate.split("T")[0]) +
                              " " +
                              _vm._s(_vm.miceInfo.eventTime.startTime) +
                              " ~ " +
                              _vm._s(_vm.miceInfo.eventTime.endTime) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c("i-icon", {
                        attrs: { size: 15, color: "#fff", name: "icon-weizhi" },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.miceInfo.city && _vm.miceInfo.city.cityDictTxt
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("i-icon", {
                        attrs: {
                          size: 15,
                          color: "#fff",
                          name: "icon-banhuiren",
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.miceInfo.qtySpeaker > 0 ? "有讲者" : "无讲者"
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "showMoreTips",
                    on: {
                      click: function ($event) {
                        _vm.showMoreTips = !_vm.showMoreTips
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showMoreTips
                            ? "收起查看详细信息"
                            : "展开查看详细信息"
                        ) +
                        " "
                    ),
                    _c("i-icon", {
                      attrs: {
                        size: 10,
                        color: "#fff",
                        name: _vm.showMoreTips
                          ? "icon-jiantoushang"
                          : "icon-jiantouxia",
                      },
                    }),
                  ],
                  1
                ),
              ]),
          !_vm.miceInfo.Name
            ? _c("i-skeleton", { attrs: { rowStyle: _vm.rowStyle } })
            : _vm._e(),
        ],
        1
      ),
      _vm.miceInfo.name && _vm.showMoreTips
        ? _c(
            "div",
            {
              staticClass: "baseEventInfo",
              style: { height: _vm.titleBoxOffsetTop },
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("会议基本信息")]),
              _c(
                "div",
                { staticClass: "cnt-box" },
                [
                  _vm._l(_vm.DirectoryIdMatchInfo, function (item, index) {
                    return [
                      !_vm.$evalTemplate(item.visibleRuleExpr)
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "cnt-item",
                              style: item.style || "",
                            },
                            [
                              _c("div", { staticClass: "left" }, [
                                _vm._v(_vm._s(item.label)),
                              ]),
                              _c("iNoCnt", {
                                staticClass: "right",
                                attrs: { txt: _vm.$evalTemplate(item.render) },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "choseBox",
                  on: {
                    click: function ($event) {
                      _vm.showMoreTips = false
                    },
                  },
                },
                [
                  _c("i-icon", {
                    attrs: {
                      size: 20,
                      color: "#909399",
                      name: "icon-jiantoushang",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "dead_line" }, [
          _c(
            "div",
            { staticClass: "text" },
            [
              _c("i-icon", {
                staticClass: "date-icon",
                attrs: { size: 16, color: "#333", name: "icon-rili" },
              }),
              _vm._v(" 截止参团日期 "),
            ],
            1
          ),
          _c("div", { staticClass: "date" }, [
            _vm._v(
              _vm._s(
                _vm.miceInfo.expiryDate && _vm.miceInfo.expiryDate.split("T")[0]
              )
            ),
          ]),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card_title" }, [_vm._v("团长信息")]),
          _c("div", { staticClass: "card_item margin-btm-20" }, [
            _c("div", { staticClass: "name" }, [
              _c("div", { staticClass: "inner_name" }, [
                _vm._v(_vm._s(_vm.miceInfo.dspName)),
              ]),
              _c("div", { staticClass: "tag" }, [
                _vm._v(
                  _vm._s(
                    _vm.miceInfo.applyUserOrganization &&
                      _vm.miceInfo.applyUserOrganization.bussinesUnitCode
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "staff_num" }, [
              _vm._v("员工号：" + _vm._s(_vm.miceInfo.createdBy)),
            ]),
            _c("div", { staticClass: "email" }, [
              _vm._v(_vm._s(_vm.miceInfo.email)),
            ]),
            _c("div", { staticClass: "email" }, [
              _vm._v(_vm._s(_vm.miceInfo.phoneNumber)),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "card padding-btm-30" },
          [
            _c("div", { staticClass: "card_title" }, [
              _vm._v("当前跟团记录 "),
              _c("div", { staticClass: "num" }, [
                _vm._v(
                  "讲者人数(" +
                    _vm._s(_vm.miceInfo.totalSpeaker) +
                    ") | 参会人数(" +
                    _vm._s(_vm.miceInfo.totalQtyAttend) +
                    ") "
                ),
              ]),
            ]),
            _vm._l(_vm.groupInfo, function (item) {
              return _c("div", { key: item.joinId, staticClass: "card_item" }, [
                _c("div", { staticClass: "name" }, [
                  _c("div", { staticClass: "inner_name" }, [
                    _vm._v(_vm._s(_vm.miceInfo.dspName)),
                  ]),
                  _c("div", { staticClass: "tag" }, [
                    _vm._v(
                      _vm._s(
                        _vm.miceInfo.applyUserOrganization &&
                          _vm.miceInfo.applyUserOrganization.bussinesUnitCode
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "tag" }, [
                    _vm._v(_vm._s(item.qtySpeaker > 0 ? "有讲者" : "无讲者")),
                  ]),
                ]),
                _c("div", { staticClass: "staff_num" }, [
                  _vm._v("员工号：" + _vm._s(item.createdBy)),
                ]),
                _c("div", { staticClass: "item_bottom" }, [
                  _c("div", { staticClass: "email_div" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.email ? item.email : "暂无邮箱")),
                    ]),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.phoneNumber)),
                    ]),
                  ]),
                  _vm.isEditRecord(item)
                    ? _c(
                        "div",
                        {
                          staticClass: "operations",
                          on: {
                            click: function ($event) {
                              return _vm.navToEditRecord(item)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "operations",
                          on: {
                            click: function ($event) {
                              return _vm.navToEditRecord(item, "disabled")
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
      ![-1, 10, 0].includes(_vm.miceInfo.status) && !_vm.isExpired()
        ? [
            !_vm.isApplier
              ? _c(
                  "div",
                  { staticClass: "bottom_btns btns_1" },
                  [
                    _vm.isJoined
                      ? _c(
                          "van-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.quit },
                          },
                          [_vm._v("退团")]
                        )
                      : _c(
                          "van-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.joinGroup },
                          },
                          [_vm._v("参团")]
                        ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "bottom_btns" },
                  [
                    _c(
                      "van-button",
                      { attrs: { type: "default" }, on: { click: _vm.quit } },
                      [_vm._v("退 团")]
                    ),
                    _c("van-button", { on: { click: _vm.cancel } }, [
                      _vm._v("取消开团"),
                    ]),
                    _c(
                      "van-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.openingGroup },
                      },
                      [_vm._v("开团成功")]
                    ),
                  ],
                  1
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }